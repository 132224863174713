.holder{
    height: 100vh;
 

}

.top-text{
    padding: 50px 0px 20px 0px;
    text-align: center;
    font-style: bold;
    font-size: 30px;
}

.payment-plan{
    display: flex;
    justify-content: center;
}

.cardi{
    text-align: center;
    border: solid 1px #cccccc;
    width: 260px;
    margin: 20px;
    padding: 20px;
    box-shadow: 2px 5px 15px #888888;
    color: #4d4d4d;
}

.cardi-header{
    background-color: #F0F2F3;
    border-radius: 100px;
    height : 150PX;
    width: 150px;
    margin: auto;
    display: flex;
    justify-content: center; 
    flex-direction: column; 
}

.cardi h1{
    color: #3CC13B;
    margin:auto 0px;
    font-size: 2.1rem;
    width: auto;
    height: auto;
    align-self: center;

}

.acc-header{
    margin: 50px auto 0px auto;
}

.confirm-input{
    width: 60%;
    text-align: center;
    margin: auto;
    padding: 10px 50px;
    box-shadow: 2px 4px 15px #888888;
    color: #4d4d4d;
    height: auto;
}

.payment-plan strong{
    color: #3E66FB;
}

.confirm-input strong{
    color: #3E66FB;
}

.confirm-input i{
    color: #3E66FB;
}

.mpesa-instructions{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    width: auto;
}

.mpesa-input{
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
}

.post-conf{
    display: flex;
    justify-content: center;
    margin: 5px auto;
    background: #3E66FB;
    color: #fff;
}

@media only screen and (max-width: 1024px) {
    .payment-plan{
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }
    
    .cardi{
        text-align: center;
        border: solid 1px #cccccc;
        width: 200px;
        margin: 10px;
        padding: 10px;
        box-shadow: 2px 5px 15px #888888;
        color: #4d4d4d;
    } 
  }

  @media only screen and (max-width: 768px) {
    
}

@media only screen and (max-width: 480px) {
    .payment-plan{
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 50px auto;
        width: auto;
        padding: 0;
    }
    
    .cardi{
        text-align: center;
        border: solid 1px #cccccc;
        width: auto;
        margin: 10px 30px;
        padding: 10px;
        box-shadow: 2px 5px 15px #888888;
        color: #4d4d4d;
    }  

    .confirm-input{
        width: auto;
        text-align: center;
        margin: 20px 30px;
        padding: 0px 10px;
        box-shadow: 2px 4px 15px #888888;
        color: #4d4d4d;
        height: auto;
    }
}