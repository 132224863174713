.hero{
    margin-bottom: 100px;
    padding: 20px 100px 0px 100px;
    background: rgb(185,189,192);
    background: linear-gradient(263deg, rgba(185,189,192,1) 13%, rgba(248,248,248,1) 100%); 
}

.prom-lead{
    padding: 8px 100px 8px 100px;
    background-color: #EE7E41;
    display: flex;
    justify-content: space-between;
    
}

.prom-lead-blue{
    padding: 8px 150px 8px 150px;
    background-color: #0059ff;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
}

.prom-lead-btn{
    background-color: #EE7E41;
    color: #ffffff;
    padding: 0px 20px 8px 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    cursor: pointer;
}

.prom-lead-btn-blue{
    background-color: #0059ff;
    color: #ffffff;
    padding: 0px 20px 8px 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    cursor: pointer;
} 



.hero-wrapper{
    display: flex;
}
.hero-left{
    width: 50%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.prep-heading{
    font-size: 2rem;
    font-weight: bold;
    color: #4D4D4D;
    margin-bottom: 31px;
    margin-top: 79px;
    text-align: left;
}

.orange{
    color: #ee7e41;
}

.hero-description{
    margin-bottom: 31px;
}

.prep-description{
    font-size: 1rem;
    color: #4D4D4D;
    margin-bottom: 1rem;
    margin: 0;
    padding: 0;
    text-align: left;
}

.getstarted{
    padding: 15px 26px;
    color: #ffffff;
    background-color: #0059ff;
    border-radius: 30px;
}

.red-btn{
    padding: 15px 65px;
    color: #ffffff;
    background-color: #0059ff;
    border-radius: 5px;
}

.getstarted:hover{
    padding: 15px 44px 15px 27px;
    color: #ffffff;
    background-color: #0059ff;
    border-radius: 30px;
}

.hero-right{
    width: 50%;
    padding: 0;
    margin: auto;
    text-align: center;
}

.hero-image{
    width: 100%;
}


.section2{
    padding: 0px 100px 0px 100px;
    color: #4D4D4D;
}

.first-text{
    text-align: center;
}

.getstart{
    text-align: left;
    padding: 0px 0px 70px 0px;
}

.info- txt{
    font-size: 0.8rem;
    color: #4D4D4D;
    margin: 0 0 11px 0;
    padding: 0;
}

.why-choose{
    font-size: 1.9rem;
    margin: 0 0 18px 0;
    padding: 0;
    font-weight: bold;
}



.why-description{
    font-size: 1rem;
    color: #4D4D4D;
    margin:  0 100px 47px 100px;
    padding: 0;
    }

.second-text{
    display: flex;
    justify-content: center;
    justify-content: space-between;
}

.schedule .full-access-img{
    width: 12%;
}

.schedule{
    width: 300px;
}



.full-access-heading{
    font-size: 1.7rem;
    margin: auto;
    padding: 15px 15px 5px 0px;
    font-weight: bold;
    text-align: left;
}

.full-access-description{
    font-size: 1rem;
    color: #4D4D4D;
    padding: 0;
    text-align: left;
    }



.section3{
    padding: 0px 100px 0px 100px;
    background-color: #FFFCF6;
    color: #4D4D4D;
}

.section3-wrapper{
    display: flex;
}

.sec3left{
    width: 50%;
    height: auto;
    margin: 0;
    padding: 0;
    text-align: center;
}

.sec3right{
    width: 50%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: start;
    align-self: center;
}

.wwd{
text-align: left;
padding: 11px 0px 11px 0px;
margin: 0;
}

.whss{
    font-size: 1.9rem;
    margin: 0 0 0 0;
    padding: 0 0 23px 0;
    font-weight: bold;
}

.s3des{
    font-size: 1rem;
    color: #4D4D4D;
    margin:  0;
    padding: 0 0 60px 0;
    text-align: left;
}

.sec3extras{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0 0 0px 0;
}

.exnum{
    color: #E06276;
    font-weight: bold;
}

.extex{
    font-size: 1rem;
    color: #4D4D4D;
}

.section4{
    padding: 100px 100px 100px 100px;
    color: #ffffff;
    background-color: #3980C6;
    
}

.section4-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.sec4left{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}

.sec4right{
    width: 50%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.sec4rightimg{
    width: 60%;
    height: auto;
    margin: 0;
    padding: 0;
    align-self: center;
}

.testimonials{
text-align: left; 
padding: 0 0 12px 0;  
font-size: 0.9rem; 
}

.sec4head{
    font-size: 1.9rem;
    margin: 0 0 0 0;
    padding: 0 0 57px 0;
    font-weight: bold;
}

.testname{
    align-items: center;
}

#iten{
    align-items: center;
}

.teststars{
    margin: 0;
    padding: 0 0 10px 0;
}

.testimony{
    font-size: 1rem;
    color: #ffffff;
    margin:  0;
    padding: 0 0 22px 0;
    text-align: center;
    
}

.tname{
    font-size: 1.3rem;
    color: #E06276;
    margin:  0;
    padding: 0 0 0 0;
    text-align: center;
}

.tdirection{
    font-size: 1rem;
    color: #4D4D4D;
    margin:  0;
    padding: 0 0 0 0;
    text-align: center;
}

.tnextprevbtn{
    display: flex;
    flex-direction: row;
    padding: 20px 0px 20px 0px;
}

.tprev{
    width: 80%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.tnext{
    width: 80%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
}

@media all and (max-width: 1024px) {
    .hero{
        width: auto;
        height: auto;
        padding: 50px 50px;
        margin: 0;
        display: flex;
        text-align: center;
    }

    .prom-lead{
        padding: 8px 50px 8px 50px;
        background-color: #EE7E41;
        display: flex;
        justify-content: space-between;
        
    }

    .hero-wrapper{
        display: flex;
        justify-content: space-between;
        text-align: center;
        margin: 0;
        padding: 0;
        left: 0;
    }

    .hero-left{
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .hero-right{
        width: 100%;
        padding: 0;
        margin: auto;
        text-align: center;
    }

    .getstarted{
        text-align: center;
        margin: 20px 0px;
    }

    .section2{
        padding: 50px 50px 0px 50px;
        
    }

    .first-text{
        text-align: center;
        padding: 0px;
    }

    .why-description{
        font-size: 1rem;
        color: #4D4D4D;
        margin:  0 ;
        padding: 0 200px 47px 200px;
        }

    .second-text{
        text-align: left;
        display: flex;
        justify-content: space-between;
    }

    .full-access-heading{
        font-size: 1.7rem;
        margin: auto;
        padding: 15px 15px 5px 0px;
        font-weight: bold;
        text-align: left;
    }
    
    .full-access-description{
        font-size: 1rem;
        color: #4D4D4D;
        padding: 0;
        text-align: left;
        }

   
    .section3{
        padding: 0px 50px 0px 50px;
        margin: 0px;
        color: #4D4D4D;
    }
    .section3-wrapper{
        display: flex;
    }

    .sec3left{
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .secleftimg{
        width: 97%;
        height: auto;
        margin: auto;
        padding: 0;
        align-self: center;
    }

    .sec3right{
        width: auto;
        padding: 0 100px;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: start;
        align-self: center;
    }

    .section4{
        padding: 0px 100px 0px 100px;
        margin: 30px 0px;
        color: #4D4D4D;
    }

    .section4-wrapper{
        display: flex;
        margin: 0;
        padding: 0;
    }

    .sec4left{
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
    }



    .sec4rightimg{
        width: 97%;
        height: auto;
        margin: auto;
        padding: 0;
        align-self: center;
    }
  }


  @media all and (max-width: 768px) {
    .hero{
        width: auto;
        height: auto;
        padding: 0 100px;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .prom-lead{
        padding: 8px 100px 8px 100px;
        background-color: #EE7E41;
        display: flex;
        justify-content: space-between;
        
    }

    .hero-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        left: 0;
    }

    .hero-left{
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .hero-right{
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .getstart{
        text-align: center;
        margin: 20px 0px;
    }

    .section2{
        padding: 50px 50px 0px 50px;
        
    }

    .first-text{
        text-align: center;
        padding: 0px;
    }

    .why-description{
        font-size: 1rem;
        color: #4D4D4D;
        margin:  0 50px 47px 50px;
        padding: 0;
        }

    .second-text{
        justify-content: center;
        display: flex;
     
    }

    .schedule .full-access-img{
        width: 20%;
    }


    
    .section3{
        padding: 0px 50px 0px 50px;
        margin: 0px;
    }
    .section3-wrapper{
        display: flex;
        flex-direction: column;
    }

    .sec3left{
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
        align-self: center;
    }

    .sec3leftimg{
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        align-self: center;
    }

    .sec3right{
        width: auto;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: start;
        align-self: center;
    }

    .section4{
        padding: 0px 100px 0px 100px;
        margin: 30px 0px;
    }

    .section4-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        padding: 0;
    }

    .sec4left{
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
    }

    .sec4right{
        display: none;
    }
  }

  @media all and (max-width: 480px) {
    .hero{
        width: auto;
        height: auto;
        padding: 0 30px;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .prom-lead-blue{
        padding: 8px 30px 8px 30px;
        background-color: #0059ff;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
    }

    .prom-lead{
        padding: 8px 30px 8px 30px;
        background-color: #EE7E41;
        display: flex;
        justify-content: space-between;
        
    }

    .hero-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        left: 0;
    }

    .hero-left{
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .hero-right{
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .prom-vid{
        width: auto;
        height: auto;
    }

    .hide-on-mobile{
        display: none;
    }

    .getstart{
        text-align: center;
        margin: 20px 0px;
    }

    .section2{
        padding: 50px 30px 50px 30px; 
    }

    .first-text{
        text-align: center;
        padding: 0px;
    }

    .schedule .full-access-heading{
        text-align: center;
    }

    .schedule .full-access-description{
        text-align: center;
    }

    .why-description{
        font-size: 1rem;
        color: #4D4D4D;
        margin:  0 30px 47px 30px;
        padding: 0;
        }

    .second-text{
        display: flex; 
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    
    .section3{
        padding: 0px 30px 0px 30px;
        margin: 0px;
    }
    .section3-wrapper{
        display: flex;
        flex-direction: column;
    }

    .sec3left{
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .sec3leftimg{
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        align-self: center;
    }

    .sec3right{
        width: auto;
        padding: 0px 30px;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: start;
        align-self: center;
    }

    .section4{
        padding: 50px 30px 0px 30px;
        margin: 30px 0px;
    }

    .section4-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        padding: 0;
    }

    .sec4left{
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
    }

    .sec4right{
        display: none;
    }
  }