.innerdiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttons-div{
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
}

.goto-btn{
    margin: 10px;
    padding: 18px 20px;
}

.goto-btn:hover{
    background: #ea5729;
    color: #ffffff; 
}

.icon-div{
    width: 100%;
    display: flex;
    justify-content: center;
    
}

#select-icon{
    display: flex;
    width:50%;
    justify-content: center;
    filter: drop-shadow(0 0 0.75rem black);  
}