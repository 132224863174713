.main-div{
    padding: 0px 150px;
    background-color: #E5E5E5;
    height: 100vh;
    }

.topic-btns{
    margin: 10px 5px 5px 5px;
    padding: 10px 5px ;
    cursor: pointer;
    background-color: #ffffff;
}

.topic-btns:hover{
    background-color:#2C73F3;
    color: aliceblue;
}

@media all and (max-width: 1024px) {
    .main-div{
        padding: 0px 100px;
        background-color: #E5E5E5;
        height: 100vh;
        }
}

@media all and (max-width: 768px) {
    .main-div{
        padding: 0px 50px;
        background-color: #E5E5E5;
        height: 100vh;
        }
}

@media all and (max-width: 480px) {
    .main-div{
        padding: 0px 30px;
        background-color: #E5E5E5;
        height: 100vh;
        }

    .subject-title{
        width: 100%;
        padding: 20px 0px 10px 0px;
    }
}