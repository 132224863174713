.main-result{
    padding: 20px 150px 0px 150px;
    height: 100vh;
    flex: 2;
    text-align: center;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    background: #F0F2F3;
}

.wholder{
    height: 100%;
    margin-bottom: 30px;
    padding-bottom: 20px;
    background: #ffffff;
    border-radius: 10px;
}

.contents img {
    width: 40%;
    height: 50%;
    padding: 10px 0px 10px 0px;
}

.q-choicess{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    
}

#answer{
    background: #2C73F3;
}

.explanations{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.ex-btn{
    border: solid #2C73F3 2px;
    cursor: pointer;
    background: #333333;
    color: #f8f8f8;
}

.ex-body{
    margin-top: 20px;
    border: solid #2C73F3 1px;
    cursor: pointer;
    background: #F0F2F3;
    color: #4c4c4c;
    width: 90%;
    padding: 12px 5px;
    align-self: center;
    border-radius: 5px;
    position: relative;
}

.qa p{
text-align: left;
}

@media all and (max-width: 1280px) {
    .main-result {
        padding: 20px 150px 0px 150px;
    }
  }

  @media all and (max-width: 900px) {
    .q-choicess{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        
    }
  }

  @media all and (max-width: 600px) {
    .main-result{
        padding: 20px 0px 0px 0px;
    }
  }