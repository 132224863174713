.contactus-main{
    height: auto;
    padding: 20px 100px 20px 100px;
    background-color: #E5E5E5;
    
}

.contactus-footer{
    height: auto;
    padding: 60px 100px 20px 100px;
    background-color: #E5E5E5;
}

.contact-wrapper {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}

.map-left{
  width: 312px;
  height: 564px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 15px;
  border: solid 1px #E5E5E5;
}

.cont-right{
    width: 50%;
    height: 564px;
}

.hed-cont{
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    letter-spacing: -1.5%;
    padding: 16px 12px ;
}

.big{
    font-size: 20px;
    font-weight: 700;
    line-height: 28.13px;
    letter-spacing: -1.5%;
    padding: 10px 10px ;
}

.small{
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    letter-spacing: -1.5%;
    padding: 30px 15px ;
}

.line-br{
    width:50%;
    text-align:left;
    margin:0;
    border: solid 1px #6686FC;
    box-shadow:  2 3 5 1px #6686FC;
    background-color: #6686FC;
}



.cont-info-cards{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.office-address{
    width: 220px;
    height: 220px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    margin-right: 20px;
    border: solid 0.5px #E0E4E8;
    border-radius: 10px;
    padding-top: 25px;
}

.email-address{
    width: 220px;
    height: 220px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    margin-right: 20px;
    border: solid 0.5px #E0E4E8;
    border-radius: 10px;
    
   
}

.call-us{
    width: 220px;
    height: 220px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    margin-right: 20px;
    border: solid 0.5px #E0E4E8;
    border-radius: 10px;
}

.cont-section{
    width: auto;
    height: 403px;
    display: flex;
    flex-direction: column;
    margin: 5px 20px;
    background-color: #ffffff;
    border: solid 0.5px #E0E4E8;
    border-radius: 10px;
    padding: 20px;
    justify-content: center;
    align-items: center;
}

.cont-form{
    width: 70%;
}





.congratulation-div{
    width: 100%;
    height: auto;
    display: none; 
    align-items: center;
    flex-direction: column;
    
}

.free{
    color: #ffffff;
    text-decoration: none;
    padding: 20px;
}

#elimumarker{
    width: 30%;
}



@media all and (max-width: 1024px) {
   
  }

  @media all and (max-width: 768px) {

    .contactus-main{
        height: auto;
        padding: 10px;
        margin: 0px;
        background-color: #E5E5E5;
        
    }

    .contact-wrapper{
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .map-left{
        display: none;
    }

    .cont-right{
        width: auto;
        height: auto;
    }

    .cont-info-cards{
        display: flex;
        flex-direction: column;
    }

    .cont-section{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        
    }

    .office-address{
        margin-bottom: 15px;
    }

    .email-address{
        margin-bottom: 15px;
    }

    .call-us{
        margin-bottom: 15px;
    }

  }

  @media all and (max-width: 480px) {

   

  }