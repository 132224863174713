.payment-loading{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dddd;
  backdrop-filter: blur(1px);
  border-radius: inherit;
  flex-direction: column;
  text-align: center;
  pointer-events: none;

  .loader{
    width: 3em;
    height: 3em;
    border-radius: 50vw;
    margin: 17px;
    border: 3px solid #bbb;
    border-left-color: #777;
    @keyframes rotation {
      to{
        transform: rotate(360deg);
      }
    }
    animation: rotation .5s infinite linear;
  }
  .message{
    font-weight: bold;
    font-size: 1.2em;
  }
}

