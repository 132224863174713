.hold-both{
    width: 100%;
}

.q-wrapper {
    display: flex;
    flex: 3;
    height:90vh;
    flex-direction: column;
    text-align: center;
    padding: 0px 150px 0px 150px;
    background-color: #E5E5E5;
}

.topic-view{
    display: none;
}

.qa{
    text-align: left;
    border-radius: 300px;
    border-color: #ffffff;
    width: auto;
}

.api-topic{
    padding: 0px 5px 5px 5px;
    border-radius: 5px;
    border-style: solid;
    border-color: #3E66FB;
    border: 1px;
    background-color: #9aacf0;
}

.questions-section {
    margin: 0px;
    text-align: center;
    overflow-x: none;
    overflow-y: auto;
    padding: 20px;
    height: auto;
}

.mbelenyums{
margin-top: 20px;
margin-bottom: 20px;
}


#prev{
    margin-right: 18px;
    padding: 20px 10px 20px 42px;
    border-radius: 5px;
    border: 1px solid #727578;
    color: #424242;
}

#prev:hover{
    background: #3E66FB;
    color: #ffffff;
}

#nxt{
    margin-right: 18px;
    padding: 20px 42px 20px 10px;
    border-radius: 5px;
    border: 1px solid #727578;
    color: #424242;
}

#nxt:hover{
    background: #3E66FB;
    color: #ffffff;
}


.qyt {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    z-index: 2;
}

.year-txt{
    font-size: 25px;
    font-weight: bold;
    color: #4D4D4D;  
}

.q-number{
    font-size: 18px;
    font-weight: bold;
    color: #4D4D4D;
}

.q-numero{
    display: flex;
    flex-direction: row;
    padding: 0px 5px;
}

.q-content{
    width: 100%;
    height: auto;
    min-height: 100px;
    padding: 20px 10px 20px 10px;
    background-color: #ffffff;
    border-radius: 5px;
    justify-self: center;
    margin-bottom: 20px;
    box-shadow: 5px 5px 8px 0px #7e7e7ec0;

}

.q-content img {
    width: 50%;
    height: 50%;
    padding: 10px 0px 10px 0px;
}

.content{
    width: 100%;
    height: auto;
    min-height: 150px;
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
    justify-self: center;
   
}

.content img {
    width: 50%;
    height: 50%;
    padding: 10px 0px 10px 0px;
}


.q-choices {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.choice.active button{
    background: #3E66FB;
}

.q-btn:hover{
    background: #3E66FB;
    color: #ffffff;
}

.q-btn{
    background: #f3f3f3;
    width: 90%;
    box-shadow: 5px 5px 8px 0px #7e7e7ec0;
    border: 0px;
    border-radius: 10px;
    text-align: center;
    outline: none;
    padding: 10px;
    color: #424242;
}

.plug{
    font-size: 12px;
}



.nxt-prev {
    padding: 30px 150px 30px 150px;
    background: #C2C9D1;
    width: auto;
    display: flex;
    justify-content: space-between;
}



table{
    width: 250px;
}

.finishbtn{
    background: #74bf24;
    color: #f8f8f8;
    padding: 10px;
    margin: 0;
    border-radius: 5px;
    text-decoration: none;
   
}

.kwinish{
    background: #74bf24;
    color: #f8f8f8;
    padding: 20px 42px;
    width: auto;
    border-radius: 5px;
    text-decoration: none;
}


@media all and (max-width: 1280px) {
    .q-wrapper {
        padding: 20px 100px 0px 100px;
    }
  }

  @media all and (max-width: 900px) {
    .q-wrapper{
        padding: 20px 30px 0px 30px;
    }

    .q-choices {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap:20px ;
    }

    .nxt-prev {
        padding: 30px 30px 30px 30px;
    }

    .finishbtn{
        background: #74bf24;
        color: #f8f8f8;
        padding: 10;
        border-radius: 5px;
        text-decoration: none;
       
    }

    .kwinish{
        background: #74bf24;
        color: #f8f8f8;
        padding: 20px 62px;
        margin-top: 5px;
        width: auto;
        border-radius: 5px;
        text-decoration: none;
    }

  }

  @media all and (max-width: 600px) {
    .q-wrapper {
        padding: 20px 0px 0px 0px;
    }
  }

  @media all and (max-width: 375px) {
   
    #prev{
        margin-right: 0;
    }
  }