.study-main{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0px 100px 0px 100px;
    background-color: #E5E5E5;
}

.heading-holder p, h6{
    text-align: left;
}

.heading-holder{
    margin-top: 50px;
}

.heading-holder h6{
    font-weight: 400;
    font-size: 48px;
    line-height: 56.25px;
    color: #4D4D4D;
}

.heading-holder p{
    font-size: 24px;
    color: #4d4d4dcb;
    

}

.guide-card-holder{
    margin-top: 70px;
    text-align: left;
    display: flex;
    flex-direction: column;
    
}

.top, .bottom{
    display: flex;
    justify-content: center;
    margin: 60px 0;
}

.guide-card{
    text-align: left;
    width: 40%;
    box-shadow: 10px 10px 14px 0px rgba(0,0,0,0.17);
    padding: 10px;
    margin: 0 60px;
    background-color: #ffffff;
    
}

.week-no, .card-title, .card-cont, .more{
    text-align: left;

}

.week-no{
    color: #6941C6;
    background-color: #EFF8FF;
    margin: 50px 0 0 0;
    padding: 2px 8px;
    width: 18%;
    border-radius: 10px;
}

.card-title{
    font-weight: 700;
    font-size: 32px;
    color: #4D4D4D;
    margin: 15px 0px;
}

.card-cont{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.more{
   color: #2C73F3;
   background-color: #ffffff;
   text-align: left;
   padding: 0;
   margin: 20px 0px 50px 0px;
}

#unstyle{
    text-decoration: none;
    padding-right: 10px;
}


@media all and (max-width: 1024px) {
   
  }

  @media all and (max-width: 811px) {
    .study-main{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px 20px 0px 20px;
    }

    .heading-holder h6{
        font-weight: 400;
        font-size: 28px;
        color: #4D4D4D;
    }
    
    .heading-holder p{
        font-size: 14px;
        color: #4d4d4dcb;
    
    }

    .top, .bottom{
        display: flex;
        justify-content: center;
        margin: 60px 0;
        flex-direction: column;
    }

    .guide-card-holder{
        margin-top: 20px;
        text-align: left;
        display: flex;
        flex-direction: column;
        
    }

    .guide-card{
        text-align: left;
        width: 90%;
        box-shadow: 10px 10px 14px 0px rgba(0,0,0,0.17);
        padding: 10px;
        margin: 20px 20px;
        
    }

    .week-no{
        color: #6941C6;
        background-color: #F9F5FF;
        margin: 50px 0 0 0;
        padding: 2px 8px;
        width: 24%;
        border-radius: 10px;
    }
  }

  @media all and (max-width: 480px) {
   
  }