.redirect-modal-main{
    padding: 100px;
}

.red-cont{
    display: flex;
    flex-direction: column;
    text-align: center;
    
}

.img-holder{
    width: 100%;
}

.logo-logo{
    width: 105px;
    height: 105px;
    align-items: center;
}

.red-heading{
    font-style: bold;
    color: #4D4D4D;
    font-size: 24px;
}

.main {
    background: #FFFFFF;
    margin: 0px;
    padding: 0px;
    height: 70px;
    width: 100%;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0px 100px 0px 100px;
}

.logo {
    width: 20%;
    margin: 15px 0px 0 0px;
    display: flex;
    padding: 0px 0px 10px 0px;
    
}

.logo-title {
    margin: 10px 0px 0px 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    width: 100%;
    white-space: nowrap;
}

.burger-btn{
    padding: 10px;
}

.logo-title a {
    color: #2C73F3;
    text-decoration: none;
}

.menu {
    margin: 15px 10px 0px 0px;
    padding:  10px 0px 0px 0px;  

}

#desko{
    list-style: none;
    text-decoration: none;
    display: flex;
    text-align: center;
}

#shaa{
    list-style: none;
    text-decoration: none;
    margin-right: 20px;
}

.logreg{
    background-color: #2C73F3;
    height: auto;
    text-decoration: none;
    color: #ffffff;
    border: #2C73F3 solid 1px;
    padding: 10px 65px;
}

#desko li{
    color: #828282;
    ;
    font-style: bold;
    font-size: 18px;
}

#desko li:hover{
    color: #111111;
    font-style: bold;
    font-size: 18px;
}

#desko li:active {
    color: #3E66FB;
    font-style: bold;
    font-size: 18px;
}



.dropdown {
    justify-content: space-between;
    align-items: center;
    display: none;
    flex-direction: column;
}

#buger {
    cursor: pointer;
    color: #333;
    

}

#buger:hover {
    color: #2C73F3;
}

.dropdown ul {
    margin: 0; 
    padding: 10px;
    background: #e5e5e5;
    width: 150px;
    height: auto;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    list-style: none;
    border-radius: 5px;
    border: solid 1px #2C73F3;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.4s ease;
    pointer-events: none;
    max-height: 0;
    display: flex;
    
}

#link{
   border-bottom: 1px solid #292929;
    
}

.dropdown li,
#link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:5px;
    
}

.dropdown li:hover {
    background: #2C73F3;
}

.dropdown a {
    color: #333;
    text-decoration: none;
    cursor: pointer;
}

#side-chich {
    margin-right: 10px;
    color: #fff;
}

.dropdown button {
    background: none;
    border: none;
    color: #f3f3f3;
}

.dropdown:focus-within>ul {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);
    max-height: 1000px;
}

@media all and (max-width: 1024px) {
    .wrapper {
        padding: 0px 100px 0px 100px;
    }

    .redirect-modal-main{
        padding: 100px 0px ;
    }
    
    .red-cont{
        display: flex;
        flex-direction: column;
        text-align: center;
        
    }
  }

  @media all and (max-width: 811px) {
    .wrapper {
        padding: 0px 100px 0px 100px;
    }

    .dropdown {
        justify-content: space-between;
        align-items: flex-end;
        display: flex;
    }

    .logo {
        width: 20%;
        margin: 30px 0px 0 0px;
        display: flex;
        padding: 0px 0px 10px 0px;
        
    }

    .desk-nav{
        display: none;
    }

    nav{
        display: flex;
        padding: auto;
    }

    .redirect-modal-main{
        padding: 0px 0px ;
    }
    
    .red-cont{
        display: flex;
        flex-direction: column;
        text-align: center;
        
    }

    .logreg{
        display: none;
    }

  }

  @media all and (max-width: 480px) {
    .wrapper {
        padding: 0px;
    }

    #buger{
        margin: auto 0px;
    }
    .redirect-modal-main{
        padding: 50px 0px ;
    }
    
    #break{
        display: none;
    }

    .red-cont{
        display: flex;
        flex-direction: column;
        text-align: center;
        
    }
  }