.main-results {
    background: #E5E5E5;
    height: 100vh;
    margin: 0px;
    padding: 20px 150px 0px 150px;
    text-align: center;
    flex-direction: column;
    align-items: center;

}

.top-portion {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    width: auto;
    border-radius: 5px 5px 0px 0px;
    ;
}

.top-portion h2 {
    margin: 0px;
    padding: 10px;

}

.result-section {
    background: #ffffff;
    width: auto;
    height: auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-radius: 0px 0px 5px 5px;
    padding: 50px 30px 50px 30px;
    font-size: 30px;
}


.per {
    margin: 0px;
    padding: 0px;
    color: #3CC13B;
}

.correct-answers {
    border: #F0F2F3 solid 1px;
    border-radius: 5px;
    padding: 20px 30px;
}

.num-corect {
    background-color: #F0F2F3;
    border-radius: 360px;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-self: center;
}

.cor-ans-text {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #4D4D4D;
    padding-bottom: 5px;
    border-bottom: 1px solid #F0F2F3;
}

#gc {
    align-self: center;
    text-align: center;
    font-size: 36px;
    color: #4D4D4D;
}



.percent {
    width: auto;
}




.fa-check-circle {
    color: #3CC13B;
}

.score {
    margin: 0px;
    align-self: start;
}

.fa-times-circle {
    color: #F03738;
}

.right i {
    color: yellow;
}

.answer-btns {
    display: none;
}

.r-btn {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

button {
    border-radius: 5px;
    border: 0px;
    padding: 15px 42px;
    color: #969CA1;
    font-size: 18px;
}

.all-ans .wrong-ans {
    background: #F0F2F3;
    color: #969CA1;
}

.view-cor-ans {
    padding-top: 20px;
}


.bottom-portion {
    background: #282828;

    padding: 10px 20px 10px 20px;
}

.ppt {
    width: 100%;
    padding: 10px 20px 10px 20px;
    background: #ea5729 marine;
}

.continue-to-topic {
    padding: 5px;

}

.cont-p {
    margin: 10px 10px;
}

.finish-btn {
    padding: 0px;
    margin: 0px;
}

#nxt {
    color: #282828;
    text-decoration: none;
}

/* larg sreens */
@media all and (max-width: 1280px) {
    .top-portion {
        margin: 20px auto;
    }

    .result-section {
        margin: 10px auto;
    }

    .rws {
        margin: 10px auto;
    }

    .answer-btns {
        margin: 10px auto;
    }

    .bottom-portion {
        margin: 10px auto;
    }
}

/* tablets screen */
@media all and (max-width: 900px) {
    .main-results {
        padding: 20px;
        text-align: left;
        height: 120vh;
    }

    .top-portion {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .score {
        margin: 0px;
        text-align: start;
    }

    .time {
        margin: 0px;
        text-align: start;
    }

    #pinr {
        text-align: left;
        padding: 5px 0px;
    }

    .cong-msg {
        text-align: left;
        padding: 5px 0px;
        display: none;
    }

    .result-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        height: auto;
    }

    .main-results {
        background: #E5E5E5;
        height: auto;
        margin: 0px;
        text-align: center;
        flex-direction: column;
        align-items: center;
    
    }

    .correct-answers {
        padding: 10px 10px;
        height: auto;
        margin: 10px 0px;
    }

    .view-cor-ans {
        text-align: center;
    }

    .continue-to-topic {
        text-align: center;
    }

}

/* mobile screen */
@media all and (max-width: 600px) {
    .top-portion {
        margin: 10px 0px 0px 0px;
    }

    .result-section {
        margin: 10px 0px 0px 0px;
    }

    .rws {
        margin: 10px 0px 0px 0px;
    }

    .answer-btns {
        margin: 10px 0px 0px 0px;
    }

    .bottom-portion {
        margin: 10px 0px 0px 0px;
    }
}