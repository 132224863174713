.close-text-question{
  background-color: #f5f5f5;
  padding: 15px;
  .content{
    font-size: .85em;
    * {display: inline;}
    em {display: block; margin-bottom: 10px;}
    p{margin: 0}

    .close-test-question{
      display: inline-block;
      cursor: pointer;
      min-width: 7em;
      height: 1em;
      border-bottom: 1px solid #000;
      padding-bottom: 20px;

      &[data-selected="true"]{
        color:#3E66FB;
        ;
        border-bottom-color:#3E66FB;
        ;
      }
    }
  }

}