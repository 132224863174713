.main-subject-div{
    padding: 100px 150px 0px 150px;
    background-color: #E5E5E5;
    height: 100vh;
    width: auto;
}

.subject-rows{
    width: auto;
    height: auto;
    display: grid;
    grid: auto auto/1fr 1fr 1fr 1fr;
    grid-gap: 15px;
}

.single-subject{
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    height: auto;
    width: auto;    
    
}

.wrap-subject{
    display: flex;
    height: 30%;
}

.sub-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    align-self: center;
}

.sub-name{
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: #4D4D4D;
    margin: auto 0;
}

.extr{
    display: flex;
    flex-direction: column;
    height: 60%;
}

.to-years{
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    background-color:#2C73F3;
    padding: 7px 15px;
    margin-bottom: 10px;
}

.to-topics{
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    background-color:#F4B12E;
    padding: 7px 12px;
    margin-bottom: 25px;
}

.otherextras{
    border-top: 1px solid #E0E4E8;
    display: flex;
    justify-content: space-between;
    height: 10%;
}

.kang{
    font-size: 15px;
}

@media all and (max-width: 1024px) {
    .main-div {
        padding: 20px 200px 0px 200px;
    }

    .otherextras{
        height: 5%;
        margin: -10px;
    }
}

@media all and (max-width: 768px) {
    .main-subject-div{
        padding: 100px 50px 100px 50px;
        height: auto;
    }
    .subject-rows{
        width: auto;
        height: auto;
        display: grid;
        grid: 246px 280px/ 2fr 2fr;
        grid-gap: 25px;
        padding: 20px;
    }

    .single-subject{
        width: auto;
   }
}

@media all and (max-width: 480px) {
    .main-div {
        padding: 20px 30px 0px 30px;
    }

    .main-subject-div{
        padding: 50px 0px 100px 0px;
        height: auto;
    }

    .subject-rows{
        padding: auto 30px;
        width: auto;
        height: auto;
        display: grid;
        grid: auto auto/1fr 1fr;
        grid-gap: 10px;
    }

    .otherextras{
        border-top: 1px solid #E0E4E8;
        display: none;
        justify-content: space-between;
        height: 10%;
    }

    .single-subject{
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
        height: auto;
        width: auto;    
        
    }
}