.nf-container{
width:100%;
padding: 0px 20px 0px 20px;
margin-top:20px;
height: 85vh;
text-align:center;
}

h1{
font-size:90px;
font-weight:800;
color: #ea5729;
font-family: 'Roboto', sans-serif;
padding: 20px 0px;
}

h2{
font-size:30px;
margin-top:-40px;
}

p{
text-align:center;
font-size:18px;
}

.nf-btn{
    margin-top: 20px;
    padding: 7px 15px;
    color: #ffffff;
    cursor: pointer;
    background-color: #3E66FB;
}

.nf-btn:hover{
    padding: 10px 17px;
}

#home-link{
    text-decoration: none;
    color: #ea5729;
}
