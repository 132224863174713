.leaderboard-main{
    height: 100vh;
    background-color: #E5E5E5;
    padding: 20px 120px 20px 120px;
    width: 100%;
    overflow: auto;
}

.leaderboard-header{
    display: flex;
    justify-content: space-between;
    font-style: bold;
    margin-top: 10px;
    
}

.challenge{
    background: #3E66FB;
    color: #fff;
    padding: 10px 16px;
    width: 100%;
    border: 0;
    border-radius: 5px;
}

.lhh{
  margin:30px 0px; ;
}

.lhh,h3{
    font-size: 30px;
    font-weight: bold;
}


#onlythisone{
  color: rgba(102, 112, 133, 1);

}

.sby{  
  width: 40%;
}

.sby,.sbm,.sbd{
    margin: 5px;
}

.main-table-div{
    width: 400px;
    border: #3E66FB solid 1px;
}

.filter-btn{
  margin: 0;
  padding: 10px 17px;
  background: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-style: bold;
  width: 197px;
  border: 1px solid rgba(208, 213, 221, 1)
}

#basic-url{
  padding: 10px 16px;
}

.to-single{
  width: 100%;
  font-size: 15px;
  padding: 0px;
}

table {
    border-collapse: collapse;
    border-radius: 5px;
    margin: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }
  
  table tr {
    background-color: #f8f8f8;
    padding: .35em;
    border-bottom:1px solid rgba(0, 0, 0, 0.1)
  }

  /* tr:nth-child(even){
      background-color: #f2f2f2;
  } */
  
  table th,
  table td {
    padding: .625em;
    text-align: center;
  }

  .up-green{
    background: rgba(236, 253, 243, 1);
    color: rgba(18, 183, 106, 1);
    border-radius: 16px;

  }

  .down-red{
    background: rgba(254, 243, 242, 1);
    color:rgba(240, 68, 56, 1);
    border-radius: 16px;

  }
  
  table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  #sing-lead{
      text-decoration: none;
      color: #fff;
  }

  .to-single{
      padding: 5px 10px;
      font-size: 15px;
      border: #3E66FB solid 1px;
      background: rgba(249, 245, 255, 1);
  }

  #single-link{
        text-decoration: none;
        color: #3E66FB;
  }

  /* modal stuff */

  .not-part{
    background: rgba(254, 243, 242, 1);
    color: rgba(180, 35, 24, 1);
    padding: 2px 8px;
}

.yes-part{
    background: rgba(236, 253, 243, 1);
    color: rgba(2, 122, 72, 1);
    padding: 2px 8px;
}

.ongoing{
    background: rgb(253, 252, 236);
    color: #7a6c02;
    padding: 2px 2px;
    width: auto;
}

.close-me{
  background: rgba(255, 75, 92, 1);
  color: #fff;
  padding: 4.4px 10px;
  float: right;
}

  
  @media all and (max-width: 1024px) {

    .challenge{
      background: #3E66FB;
      color: #fff;
      padding:  10px 15px;
      margin: 0px 10px;
      font-size: 18px;
      width: auto;
  }

  .leaderboard-main{
    height: 100vh;
    background-color: #E5E5E5;
    padding: 20px 50px 20px 50px;
    width: 100%;
}
  }

  @media all and (max-width: 768px) {
    .challenge{
      background: #3E66FB;
      color: #fff;
      padding: 0;
  }

  .leaderboard-main{
    height: 100vh;
    background-color: #E5E5E5;
    padding: 20px 20px 20px 20px;
    width: 100%;
}

table {
  border: 0;
}

.table-section{
  overflow: scroll;
  height: 85vh;
}

table caption {
  font-size: 1.3em;
}

table thead {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

table tr {
  border-bottom: 3px solid #ddd;
  display: block;
  margin-bottom: .625em;
}

table td {
  border-bottom: 1px solid #ddd;
  display: block;
  font-size: .8em;
  text-align: right;
}

table td::before {
  /*
  * aria-label has no advantage, it won't be read inside a table
  content: attr(aria-label);
  */
  content: attr(data-label);
  float: left;
  font-weight: bold;
  text-transform: uppercase;
}

table td:last-child {
  border-bottom: 0;
}
  }

  @media all and (max-width: 480px) {
   
    .sby,.sbm,.sbd{
      display: none;
  }

  .challenge{
    background: #3E66FB;
    color: #fff;
    padding:  10px 15px;
    margin: 10px 10px;
    font-size: 18px;
    width: 200px;
}
  }