.FormCenter {
    margin-bottom: 0px;
    height: auto;
    display: flex;
    text-align: center;
    width: auto;
  }

  .login-info{
    width: auto;
    background-color: #D8E0FE;
    height: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .login-section{
    padding: 0 50px;
    height: 100vh;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  section4-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    height: 100vh;
    margin: auto 0;
  }

  .logsec4head{
    text-align: left;
    font-weight: bold;
    color: #4d4d4d;
  }

  .testimony p{
    text-align: left;
    font-weight:400;
  }

  #form{
    width: 40%;
    background-color: #ffffff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    
    padding: 0 50px;
   }

   .form-wrapper{
    align-self: center;
    width: 100%;   
   }

  
  .FormTitle {
    color: #707C8B;
    font-weight: 100;
    margin-bottom: 0px;
  }
  
  .FormTitle__Link {
    color: #707C8B;
    text-decoration: none;
    display: inline-block;
    font-size: 1.7em;
    margin: 0 10px;
    padding-bottom: 2px;
  }
  
  .FormTitle__Link:first-child {
    margin-left: 0;
  }
  
  .FormTitle__Link--Active {
    color: white;
    border-bottom: 1px solid #3E66FB;
    ;
  }
  
  .FormField {
    margin-bottom: 8px;
    text-align: left;
  }

  .FormFields{
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
  }

  .form-label{
    text-align: left;
  }

  
  .FormField__Label {
    display: flex;
    font-size: .85em;
    color: aliceblue;
    font-style: bold;
    margin-left:50px;
  }
  .FormField__Input {
    width: 100%;
    background-color: transparent;
    border: none;
    color: 3E66FB;
    outline: none;
    border-bottom: 1px solid #445366;
    font-size: 1em;
    font-weight: 300;
    padding-bottom: 10px;
    margin-top: 5px;
  }
  
  .FormField__Input::placeholder {
    color: #3E66FB;
  }
  
  .FormField__Button {
    background-color: #3E66FB;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: .8em;
    font-weight: 500;
    margin-top: 10px;
  }
  
  .FormField__Link {
    color: #66707D;
    text-decoration: none;
    display: inline-block;
    border-bottom: 1.5px solid #3E66FB;
    padding-bottom: 5px;
    margin-top: 10px;
  }
  
  .FormField__CheckboxLabel {
    color: #646F7D;
    font-size: .8em;
  }
  
  .FormField__Checkbox {
    position: relative;
    top: 1.5px;
  }
  
  .FormField__TermsLink {
    color: white;
    border-bottom: 1px solid #3E66FB;
    text-decoration: none;
    display: inline-block;
    padding-bottom: 2px;
    margin-left: 5px;
  }

  @media all and (max-width: 1024px) {
    .FormCenter {
        
    }
  }

  @media all and (max-width: 768px) {
    .login-info{
      display: none;
    }

    #form{
      width: 100%;
      padding: 0px 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 0;
    }
  }

  @media all and (max-width: 480px) {
    .FormCenter {
        padding: 20px 5px 0px 5px;
    }
  }