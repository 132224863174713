.main-holder{
    margin: 0px;
    padding: 20px 400px 0px 400px;
    height: 85vh;
    flex: 2;
    width: 100%;
    overflow-y: scroll;
}

.single-result{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    height: auto;
    width: 100%;
    background: #292929;
    border-radius: 10px;
}

.topic-holder{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
}

.topic-tittle{
    flex: 1;
    width: 100%;
}

.marks{
    flex: 1;
    margin: 0;
    padding: 0;
}


@media all and (max-width: 1280px) {
    .main-holder {
        padding: 20px 200px 0px 200px;
    }
  }

  @media all and (max-width: 900px) {
    .main-holder{
        padding: 20px 100px 0px 100px;
    }
  }

  @media all and (max-width: 600px) {
    .main-holder {
        padding: 20px 5px 0px 5px;
    }
  }