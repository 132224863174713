.pagination{
    width: 50%;
    display: flex;
    padding: 2px;;
    text-decoration: none;
    background: #2C73F3;
    color: aliceblue;
    cursor: pointer;
    border-radius: 10px;
    list-style: none;
    align-self: center;
    
}

.page-link{
    border-right: 1px solid #ffffff;
    flex: 1;
    text-decoration: none;
    text-align: center;
}

.page-link:hover{
    background: #ffffff;
    border-radius: 10px;
    color: #2C73F3;
}
 