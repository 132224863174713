.maain{
    margin: 0px;
    height: 90vh;
    padding: 20px 400px 0px 400px;
    background: #E5E5E5;    
}

.everything-else{
    background: #E5E5E5;
    margin: 0 auto;
    width: auto;
    height: 70vh;
    overflow-y: auto;
    border-radius: 5px;
    padding: 2px 10px;
    
}

.everything-else:-webkit-scrollbar {
    width: 1px;
    background: #3CC13B; 
  }

.subject-title{
    padding: 0px 100px;
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.syt{
    text-align: center;
}

.syd{
    font-size: 12px;
    color: #4d4d4d7c;
}

.years-section{
    width: 50vh;
    overflow: auto;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
}

.singleyear{
    display: flex;
    width: auto;
    flex-direction: column;
    background: #ffffff;
    text-decoration: none;
    margin: 0px 0px;
    color: #4D4D4D;
}

.singleyear:hover{
    background-color:#F0F2F3;
}


.name-btn{
    display: flex;
    justify-content: space-between;
    text-align: center;
    height: auto;
    padding: 2px 10px;
    width: auto;
    font-size: 10px;

}

.expire{
    text-align: center;
    margin: auto 0;
    color: #2C73F3;
}


.nt-sub-singleyear{
    display: flex;
    width: auto;
    background: #ffffff;
    padding: 20px 0px;
    border-radius: 10px;
    margin: 10px 10px 10px 10px;
    color: #4d4d4d;
    text-decoration: none;
}

.nt-sub-singleyear:hover{
    background-color:#F0F2F3;
}

.nt-sub{
    padding: 0px 0px 0px 10px;
}

.nt-su-txt{
    font-size: 1.4rem;
    padding: 10px 0px;
    margin: 0;
}


.single-year.paid{
    box-shadow: 5px 5px 8px 0px rgba(0, 255, 255, 0.37);
}



.year-card{
    background: #ffffff;    
    text-decoration: none;
    display: flex;
    flex-direction: column;
    margin: 5px auto;
}

.year-card:hover{
    background: #F0F2F3;    
    text-decoration: none;
    display: flex;
    flex-direction: column;
    border-radius:10px;
}

.do-by-year{
    padding: 10px;
    width: 50%;
    display: flex;
    justify-content: center;
}

.do-by-year:hover{
    background: #ea5729;
    cursor: pointer;
    color: #ffffff;
}

.itm-name{
    font-size: 1.5rem;
    width: auto;
    align-items: flex-start;
    margin: 0;
    padding: 5px 0px 10px 0px;
}

#lock{
    flex: 1 1;
    padding: 0px 20px;
    color: red;
    margin: auto;
    text-align: end;
}

.pagination-section{
    width: auto;
    color: aliceblue;
    text-decoration: none;
    padding: 20px 10px;
}

.bg-modal{
    padding-top: 20px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content{
    width: auto;
    background: #f3f3f3;
    border-radius: 10px;
    text-align: center;
    color: #292929;
    position: relative;
    padding: 0 0 20px 0;
    margin: 0px 20px;
    height: auto;
}

.close{
    position: absolute;
    top: 0;
    font-size: 42px;
    right: 14px;
    transform: rotate(45deg);
    cursor: pointer;
}

.payment-dropdown{
    width: 90%;
    display: block;
    margin: 10px auto;
}

.payment-dropdown select{
    padding: 5px 10px;
}

.payment-steps{
    text-align: left;
    margin: 10px;
    list-style: decimal;
}

.payment-steps li{
    margin: 7px 0;
}

.till-put{
    width: 90%;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid #445366;
    font-weight: 300;
    padding: 6px 5px 6px;
    margin: 7px 0;
}

.code-error{
    font-size: .8em;
    color: #cc0808;
    margin: -6px 0 8px;
}

.wani{
    font-size: .75em;
    margin: 0 0 15px 0;
    color: #cc0808;
}

.pay-btn{
    border: 0;
    border-radius: 5px;
    background: rgb(12, 94, 12);
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    padding: 7px 15px;
}

.pay{
    width: 85%;
    margin: auto;
}

@media all and (max-width: 1024px) {
    .maain {
        padding: 20px 150px 0 150px;
    }
}

@media all and (max-width: 768px) {
    .maain{
        padding: 20px 100px 0 100px;
    }
}

@media all and (max-width: 480px) {
    .maain {
        padding: 20px 10px;
    }
    .year-card{
        background: #ffffff;
        padding: 10px;
        border-radius: 10px;
        margin:10px 10px 10px 10px;
        color: #f3f3f3;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .years-section{
        width: auto;
        overflow: auto;
        text-align: center;
        margin: 0 auto;
        padding: 10px;
    }
}

